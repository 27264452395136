import React, { useEffect, useState } from "react";
import { Select, Status } from "@sie/kodama-ui-components";
import { getSandboxes } from "./SandboxService";
import "./SandboxSwitcher.css";

const maxItemsToShowWithoutSearch = 9; // from PUCL guidelines (could be a prop)

const selectedSandboxKey = 'ps_selected_sbid';

const SandboxSwitcher = ({
  // required props
  onSelectSandbox,
  // optional props - override or accept defaults
  selectedSandboxId = "", // "" == none selected
  label = "Sandbox",
  placeholder = "Select a Sandbox",
  tooltip = "Select from a list of sandboxes",
  loadingMessage = "Loading Sandboxes...",
  loadingError = "Error loading sandboxes",
  noSandboxesError = "No sandboxes available",
  horizontal = true,
}) => {
  const [errorStr, setErrorStr] = useState("");
  const [sandboxOptions, setSandboxOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const retrieveSandboxes = async () => {
    try {
      const sbs = await getSandboxes();
      const options = sbs.map((sandbox) => ({
        label: `${sandbox.name} | ${sandbox.sandboxId}`,
        value: sandbox.sandboxId,
      }));
      setSandboxOptions(options);
      if (options.length == 0) {
        setErrorStr(noSandboxesError);
        onSelectSandbox('')
      } else {
        let selectedSandbox = options[0].value
        const storedSbid = localStorage.getItem(selectedSandboxKey)
        if (storedSbid){
          const isPresent = options.some(option => option.value === storedSbid)
          if (isPresent){
            selectedSandbox = storedSbid
          }
        }
        onSelectSandbox(selectedSandbox)
      }
      setLoading(false);
    } catch (e) {
      setErrorStr(loadingError);
      setSandboxOptions([]);
      onSelectSandbox('');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      retrieveSandboxes();
    }
  }, [loading]); // eslint-disable-line

  const onChange = (item) => {
    onSelectSandbox(item.value);
  };

  // display the currenly selected sandbox
  let selectedOption;
  if (selectedSandboxId) {
    selectedOption = sandboxOptions.find((o) => o.value === selectedSandboxId);
  }
  if (!selectedOption) {
    selectedOption = null; // empty string will hide placeholder, null required
  }

  return (
    <div id="sandbox-switcher">
      <div
        id="sandbox-switcher-select-wrapper"
        className={horizontal ? "horiz" : "vert"}
      >
        <div>
          {label}
          <span sie-tooltip={tooltip}>
            <span className="question-icon"></span>
          </span>
        </div>
        <div>
          <Select
            options={sandboxOptions}
            id="sandbox-switcher-select"
            placeholder={placeholder}
            value={selectedOption}
            onChange={onChange}
            isSearchable={sandboxOptions.length > maxItemsToShowWithoutSearch}
            isLoading={loading}
            loadingMessage={() => {
              return loadingMessage;
            }}
          />
        </div>
        <div></div>
        <div> {errorStr && <Status type="error" message={errorStr} />}</div>
      </div>
    </div>
  );
};

export default SandboxSwitcher;
