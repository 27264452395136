import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Select, TextField } from "@sie/kodama-ui-components";
import * as service from "./NPCommService";
import "./NPCommMetadataModal.css";
import Loader from "@sie/kodama-ui-components/lib/Loader";
import ImageUploader from "./ImageUploader";
import LockStatusIndicator from "../LockStatusIndicator";
import FormSection from "./FormSection";
import SupportedPlatformDescription from "./SupportedPlatformDescription";
import SandboxSwitcher from "../sandbox/SandboxSwitcher";
import config from "../config";

const shouldLockSupportedPlatforms = (npCommMetadata) => {
  if (!npCommMetadata.integrationTypes) {
    //use supported platforms to determine supported platforms should be locked. By default we are going to assume S2S if both platforms are present
    return npCommMetadata.supportedPlatforms.length === 2;
  } else {
    return checkIntegrationTypesIsS2S(npCommMetadata.integrationTypes);
  }
};

function checkIntegrationTypesIsS2S(integrationTypes = {}) {
  return (
    integrationTypes?.psConsole?.[0] === "S2S" &&
    integrationTypes?.psPc?.[0] === "S2S"
  );
}

export default class NPCommMetadataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      loadError: false,
      saveError: false,
      languages: {},
      selectedSandboxId: "",
    };
  }

  componentDidMount = () => {
    if (this.props.showSandboxControls){
      //load sandbox data
      this.initializeEmptySandbox();
    } else {
      this.loadNonSandboxData();
    }
  };

  //lock all fields except sandbox selector
  initializeEmptySandbox = () => {
    service.getAllLanguages().then(data => {
      const lock = ({defaultLangEditable: false, addLangEnabled: false, removeLangEnabled: false, platformEditable: false, isS2S: false})
      this.setState({
        lock,
        languages: this._languagesAsOptions(data)
      })
    }).catch(() => {
      this.setState({ loading: false, loadError: true });
    })
  }

  loadNonSandboxData = () => {
    let npCommId = this.props.npCommId;
    let contextType = this.props.contextType;
    Promise.all([
      service.getContextMetadata(contextType, npCommId),
      service.getAllLanguages(),
    ])
        .then((data) => {
          const npCommMetadata = data[0];
          const lock = this.determineLocking(npCommMetadata);

          this.setState({
            loading: false,
            ...npCommMetadata,
            lock,
            languages: this._languagesAsOptions(data[1]),
          });
        })
        .catch((error) => {
          this.setState({ loading: false, loadError: true });
        });
  };

  determineLocking = (npCommMetadata) => {
    const {
      editable,
      platformEditable,
      lockStatus = "UNLOCKED",
    } = npCommMetadata;

    const unlocked = lockStatus === "UNLOCKED";
    const partiallyLocked = lockStatus === "PARTIALLY_LOCKED";

    const defaultLangEditable = editable && unlocked;
    const addLangEnabled = editable && (unlocked || partiallyLocked);
    const removeLangEnabled = editable && (unlocked || partiallyLocked);
    const platFormEditable = platformEditable && (unlocked || partiallyLocked);
    const isS2S =
      !config.kodama.hideIntegrationTypes &&
      shouldLockSupportedPlatforms(npCommMetadata);

    return {
      defaultLangEditable,
      addLangEnabled,
      removeLangEnabled,
      platformEditable: platFormEditable,
      isS2S,
    };
  };

  _languagesAsOptions = (languages) => {
    return Object.entries(languages).map((entry) => {
      return { value: entry[0], label: entry[1] };
    });
  };
  defaultLanguageChange = (entry) => {
    let supportedLanguages = this.state.supportedLanguages;
    if (!supportedLanguages.includes(entry.value)) {
      supportedLanguages = [entry.value, ...supportedLanguages];
    }
    this.setState({ defaultLanguage: entry.value, supportedLanguages });
  };
  handleNameChange = (event) => {
    const name = event.target.value;
    const nameError =
      name.trim().length === 0
        ? "Display Name must be present"
        : name.length > 100
        ? "Display name cannot be greater than 100 characters"
        : null;
    this.setState({ name, nameError });
  };

  handleImageChange = (imageUrl) => {
    this.setState({ imageUrl });
  };

  handleSupportedLanguagesChange = (values, actionDetails) => {
    const {
      supportedLanguages,
      defaultLanguage,
      lock = { addLangEnabled: true, removeLangEnabled: true },
    } = this.state;
    if (
      actionDetails.action === "remove-value" &&
      actionDetails.removedValue.value === defaultLanguage
    ) {
      //  short-circuit out if user attempts to delete the default language
      return;
    }
    if (
      actionDetails.action === "select-option" ||
      actionDetails.action === "set-value"
    ) {
      //user selected another option = add to list
      if (lock.addLangEnabled) {
        const newList = [...supportedLanguages, actionDetails.option.value];
        this.setState({ supportedLanguages: newList });
      }
    }
    if (actionDetails.action === "remove-value") {
      const index = supportedLanguages.findIndex(
        (language) => actionDetails.removedValue.value === language
      );
      const newList = [
        ...supportedLanguages.slice(0, index),
        ...supportedLanguages.slice(index + 1),
      ];
      this.setState({ supportedLanguages: newList });
    }
  };

  handleIntegrationTypes = (supportedPlatforms) => {
    const { integrationTypes } = this.state;
    if (!integrationTypes) {
      return null;
    }
    if (checkIntegrationTypesIsS2S(integrationTypes)) {
      return this.state.integrationTypes;
    } else {
      return supportedPlatforms.reduce((acc, platform) => {
        if (platform === "PSCONSOLE") {
          acc.psConsole = ["ClientUnlock"];
        }
        if (platform === "PSPC") {
          acc.psPc = ["C2S"];
        }
        return acc;
      }, {});
    }
  };

  handleSupportedPlatformChange = (values, actionDetails) => {
    const { supportedPlatforms } = this.state;

    if (actionDetails.action === "remove-value") {
      const index = supportedPlatforms.findIndex(
        (platform) => actionDetails.removedValue.value === platform
      );
      const newList = [
        ...supportedPlatforms.slice(0, index),
        ...supportedPlatforms.slice(index + 1),
      ];
      const supportedPlatformErrorMsg =
        newList.length === 0 ? "You must select at least one platform." : null;
      const integrationTypes = this.handleIntegrationTypes(newList);

      this.setState({
        supportedPlatforms: newList,
        supportedPlatformError: supportedPlatformErrorMsg,
        integrationTypes,
      });
      return;
    }
    if (
      actionDetails.action === "select-option" ||
      actionDetails.action === "set-value"
    ) {
      const newList = [...supportedPlatforms, actionDetails.option.value];
      const integrationTypes = this.handleIntegrationTypes(newList);
      this.setState({
        supportedPlatforms: newList,
        supportedPlatformError: null,
        integrationTypes,
      });
    }
  };

  onSwitchSandbox = (sandboxId) => {
    this.setState({ selectedSandboxId: sandboxId, loading: true }, this.fetchSandboxMetadata);
  };

  fetchSandboxMetadata = () => {
    const npCommId = this.props.npCommId;
    const contextType = this.props.contextType;
    const sandboxId = this.state.selectedSandboxId;
    if (sandboxId === ''){
      this.setState({loading: false})
    } else {
      service.getContextMetadata(contextType, npCommId, sandboxId).then(npCommMetadata => {
        this.setState({
          ...npCommMetadata,
          loading: false,
          loadError: false
        });
      }).catch(() => {
        this.setState({loading: false, loadError: true})
      });
    }
  };

  onSave = () => {
    const npCommMetadata = {
      id: this.state.id,
      name: this.state.name,
      imageUrl: this.state.imageUrl,
      defaultLanguage: this.state.defaultLanguage,
      supportedLanguages: this.state.supportedLanguages,
      writeAccess: this.state.editable,
      published: this.state.published,
      eventType: this.state.eventType,
      lockStatus: this.state.lockStatus,
      supportedPlatforms: this.state.supportedPlatforms,
      integrationTypes: this.state.integrationTypes,
    };
    if (config.kodama.hideIntegrationTypes || !this.state.integrationTypes) {
      delete npCommMetadata.integrationTypes;
    }
    const platformEditable = this.state.platformEditable;
    const contextType = this.props.contextType;
    this.setState({ saving: true, saveError: false }, () => {
      service
        .saveNpIdMetadata(contextType, npCommMetadata)
        .then(() => {
          this.setState({ saving: false }, () =>
            this.props.onSaveComplete(
              Object.assign({}, npCommMetadata, { platformEditable })
            )
          );
        })
        .catch((error) => {
          this.setState({ saving: false, saveError: true });
        });
    });
  };

  renderForm = () => {
    if (this.state.loadError)
      return (
        <div className="alert alert-danger">
          An error occurred while loading metatdata.
        </div>
      );
    //if loading (non-sandboxed ) OR loading sandbox ( but waiting for languages to load ), show Loader
    if ((this.state.loading && !this.props.showSandboxControls) || (this.state.loading && this.props.showSandboxControls && Object.keys(this.state.languages).length === 0)){
      return <Loader />
    }
    const {
      name,
      languages,
      supportedLanguages = [],
      defaultLanguage,
      saveError,
      nameError,
      editable,
      lockStatus,
      published,
      npSupportedLanguages = [],
      platformEditable,
      supportedPlatforms = [],
      npSupportedPlatforms = [],
      supportedPlatformError,
      lock = {
        defaultLangEditable: editable,
        addLangEnabled: editable,
        removeLangEnabled: editable,
        platformEditable: platformEditable,
      },
      loading,
    } = this.state;

    const supportedLanguagesOptions = languages
      .filter((entry) => supportedLanguages.includes(entry.value))
      .map((language) => {
        if (
          lockStatus === "PARTIALLY_LOCKED" &&
          npSupportedLanguages &&
          npSupportedLanguages.includes(language.value)
        ) {
          return Object.assign({}, language, { isReadOnly: true });
        } else if (language.value === defaultLanguage) {
          return Object.assign({}, language, { isReadOnly: true });
        } else {
          return language;
        }
      });
    const defaultLanguageOption = languages.find(
      (entry) => defaultLanguage === entry.value
    );

    const supportedPlatformsOptions = [
      { label: "PSCONSOLE", value: "PSCONSOLE" },
      { label: "PSPC", value: "PSPC" },
    ];

    const selectedPlatformForMulti = supportedPlatformsOptions
      .filter((entry) => supportedPlatforms.includes(entry.value))
      .map((platform) => {
        if (
          lockStatus === "PARTIALLY_LOCKED" &&
          npSupportedPlatforms &&
          npSupportedPlatforms.includes(platform.value)
        ) {
          return Object.assign({}, platform, { isReadOnly: true });
        } else {
          return platform;
        }
      });
    const formBody = [(
        <FormSection
            title="Basic Information"
            description="This information will persist across all features, allowing you to quickly identify which NP Communication ID you are working on"
        >
          <div className="row metadata-section">
            <div className="col-md-6">
              <TextField
                  id="npComm-name"
                  defaultValue={name}
                  size="big"
                  label="Reference Name"
                  onChange={this.handleNameChange}
                  errormessage={nameError}
                  disabled={!editable}
              />
            </div>
            <div className="col-md-6" />
          </div>
          <div className="row metadata-section metadata-section__image">
            <div className="col-md-12">
              <label>Image Icon</label>
              {this.state.imageUrl ? (
                  <p>Custom icon is uploaded.</p>
              ) : (
                  <p>
                    Default icon is selected.{" "}
                    {editable
                        ? "Please upload a 50x50 PNG image to override it."
                        : ""}
                  </p>
              )}
            </div>
            <div className="col-md-6">
              <ImageUploader
                  imageUrl={this.state.imageUrl}
                  handleImageChange={this.handleImageChange}
                  contextType={this.props.contextType}
                  editable={editable}
              />
            </div>
          </div>
        </FormSection>),(
       <FormSection
        title="Supported Localized Languages"
        description="Supported Languages will allow you to localize data for the localized versions of your game. Changing your default language can effect network services (UDS, Trophies, Game Help, Leaderboards, Matchmaking) and users for this NP Comm ID. Proceed with caution."
    >
      <div className="row metadata-section">
        <div className="col-md-6">
          <Select
              id="npComm-defaultLanguage"
              className="form-group"
              label="Default Language"
              defaultValue={defaultLanguageOption}
              isSearchable={false}
              isDisabled={!lock.defaultLangEditable}
              options={languages}
              onChange={this.defaultLanguageChange}
          />
        </div>
      </div>

      <div className="row metadata-section">
        <div className="col-md-6">
          <Select
              id="npComm-supportedLanguages"
              className={`form-group ${
                  !lock.removeLangEnabled ? "prohibit-delete" : ""
              }`}
              label="Supported Languages"
              placeholder="Choose Languages"
              value={supportedLanguagesOptions}
              isMulti
              isDisabled={!lock.addLangEnabled && !lock.removeLangEnabled}
              options={languages}
              onChange={this.handleSupportedLanguagesChange}
          />
        </div>
      </div>
      <SupportedPlatformDescription
          contextType={this.props.contextType}
          platFormEditable={platformEditable}
      />
      <div className="row metadata-section">
        <div className="col-md-6">
          <Select
              id="npComm-supportedPlatform"
              className={`form-group ${
                  !lock.platformEditable || lock.isS2S ? "prohibit-delete" : ""
              }`}
              label="Supported Platform"
              value={selectedPlatformForMulti}
              isMulti
              isSearchable={false}
              isDisabled={!lock.platformEditable || lock.isS2S}
              options={supportedPlatformsOptions}
              onChange={this.handleSupportedPlatformChange}
              errormessage={supportedPlatformError}
          />
        </div>
      </div>
      {platformEditable && lock.isS2S && (
          <div className={"row integration-warning"}>
              <span className={"text-danger"}>
                Both PSConsole and PSPC are required in the supported platforms
                because integration type is set to S2S. Please update the
                integration type in UDS management tool to C2S to enable update.
              </span>
          </div>
      )}
    </FormSection>)];

    return (
      <React.Fragment>
        {saveError && (
          <div className="alert alert-danger">
            An error occurred while saving metadata.
          </div>
        )}
        {this.props.showSandboxControls && (
          <div className="row metadata-section">
            <div className="col-md-6">
              <SandboxSwitcher
                onSelectSandbox={this.onSwitchSandbox}
                selectedSandboxId={this.state.selectedSandboxId}
                horizontal={false}
              />
            </div>
          </div>
        )}
        {loading && (
            <Loader />
        )}
        {!loading && (!this.props.showSandboxControls || (this.props.showSandboxControls && this.state.selectedSandboxId)) && formBody}
      </React.Fragment>
    );
  };

  renderFooter = () => {
    const {
      editable,
      saving,
      nameError,
      platformEditable,
      supportedPlatformError,
    } = this.state;
    const { showSandboxControls } = this.props;
    const saveDisabled = !!nameError || !!supportedPlatformError;

    if (!showSandboxControls && (editable || platformEditable)) {
      return (
        <Modal.Footer>
          <Button
            bsStyle="secondary"
            disabled={!!saving}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button
            bsStyle="primary"
            className={saving ? "sie-loading" : ""}
            disabled={saveDisabled}
            onClick={this.onSave}
          >
            Save
          </Button>
        </Modal.Footer>
      );
    } else {
      return (
        <Modal.Footer>
          <Button bsStyle="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      );
    }
  };
  render() {
    const { loading, loadError, lockStatus } = this.state;
    const npCommId = this.props.npCommId;
    const lockStatusText = this.props.showSandboxControls? "sandbox":""
    return (
      <div>
        <Modal
          show={true}
          id="npComm-metadata-modal"
          onHide={this.props.onClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Metadata Manager for {npCommId}</Modal.Title>
            <LockStatusIndicator lockStatus={lockStatus} text={lockStatusText} />
          </Modal.Header>
          <Modal.Body>{this.renderForm()}</Modal.Body>

          {!loading && !loadError ? this.renderFooter() : ""}
        </Modal>
      </div>
    );
  }
}
