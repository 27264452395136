import axios from "axios";
import config from "./config";
import { parseQueryParameters } from "./utils";

const apiUrl = config.kodama.apiUrl;

function getAppGroupConfig(applicationGroupId) {
  return new Promise((res, rej) => {
    axios
      .get(`${apiUrl}/applicationGroup/v1/config`, {
        headers: { "X-App-Group": applicationGroupId },
      })
      .then((response) => res(response.data))
      .catch((err) => {
        if (err.response.status === 404) {
          rej(`Application Group ${applicationGroupId} is not valid.`);
        } else {
          rej(err.message);
        }
      });
  });
}

function getContextMetadata(location, appGroupConfig) {
  const { CONTEXT_VALUE } = config;
  let errMessage = "404 - Page Not Found";
  if (!appGroupConfig.contextType) {
    return Promise.resolve([]);
  }

  //   const options = [{displayName: 'Default Service Group', contextValue: 'NPWR17250_00'}, { contextValue:'NDNC10367_00', displayName:'Aarons NP Comm'},{contextValue:'NPWR00001_00', displayName:'E1 Test'}];
  return new Promise((res, rej) => {
    axios
      .get(`${apiUrl}/contextMetadata/v1/context`)
      .then((response) => {
        // LOGIC for when user attempts to deep link
        if (location.search) {
          const queryObj = parseQueryParameters(location.search.substr(1));
          const contextTypeKey = appGroupConfig.contextType;
          const contextValue = queryObj[CONTEXT_VALUE];

          if (contextValue) {
            const doesExist = response.data.some(
              (option) => option.contextValue === contextValue
            );
            if (!doesExist) {
              console.error(
                `Tried to look for ${contextTypeKey} ${contextValue} but does not exist in users metadata list`
              );
              throw new Error(errMessage);
            }
          }
        }

        return res(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          rej(new Error("You do not have permissions to continue further"));
        } else {
          rej(err);
        }
      });
  });

  // TODO: Be sure to account for error message when server returns it
}

function fetchMenuItems(contextValue) {
  const suffix = contextValue ? `?contextValue=${contextValue}` : "";
  return axios
    .get(`${apiUrl}/navigation/v1/menuItem${suffix}`)
    .then((response) => response.data);
}

function getUserFeature(featureId) {
  const url = `${apiUrl}/feature/v1/${featureId}`;
  return axios.get(url).then((response) => response.data);
}

export {
  getAppGroupConfig,
  getContextMetadata,
  fetchMenuItems,
  getUserFeature,
};
