import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@sie/kodama-ui-components";
import "./SandboxModeSwitchAlert.css";

const SandboxModeSwitchAlert = ({ onConfirm, onClose }) => {
  return (
    <div>
      <Modal
        show={true}
        id="sandboxmodeswitchalert-modal"
        onHide={onClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Switch to sandbox</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Click "Confirm" to switch into the sandbox environment. You can switch
          back at any time.
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="link" onClick={onClose}>
            Cancel
          </Button>
          <Button bsStyle="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SandboxModeSwitchAlert;
