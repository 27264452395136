import React from 'react';
import PropTypes from 'prop-types';
import './SandboxToggle.css';

function SandboxToggle({ onClick, checked, label }) {
    return (
        <div className={"toggle-switch-container"} style={{float: "right", marginTop:"0.5em"}}>
            <i className={`glyphicon glyphicon-ok ${checked?'':'hidden'}`} onClick={onClick}></i>
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    name="toggle-switch-input"
                    id="toggle-switch-input"
                    onChange={onClick}
                    checked={checked ? checked.toString() : ''}
                />
                <label className="toggle-switch-label" htmlFor="toggle-switch-input" id="toggle-switch-label">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                </label>
            </div>
            <div onClick={onClick}>{label}</div>
        </div>
    );
}

SandboxToggle.propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.string
};

SandboxToggle.defaultProps = {
    onClick: () => {},
    checked: false,
    label: 'Switch to Sandbox'
};

export default SandboxToggle;