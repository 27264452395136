import React from "react";
import Select, { components } from "react-select";
import ContextViewer from "./ContextViewer";
import { Glyphicon } from "react-bootstrap";
import NPCommMetadataModal from "./npcomm/NPCommMetadataModal";
import config from "./config";
import { sendMessageToIFrameElement } from "./utils";
import NPTitleMetadataModal from "./npcomm/NPTitleMetadataModal";
import PropTypes from "prop-types";

const SIEDropdownIndicator = (props) => {
  const { selectProps } = props;
  const additionalClass = selectProps.hasError ? " has_error" : "";
  return (
    <components.DropdownIndicator className={additionalClass} {...props}>
      <span>
        <i className="glyphicon glyphicon-chevron-down"></i>
      </span>
    </components.DropdownIndicator>
  );
};

const SingleValueContainer = (props) => {
  const { children, ...rest } = props;
  const childrenArray = children.split("|");
  const contextMetadata = {
    imgUrl: childrenArray[0],
    displayName: childrenArray[1],
    contextValue: childrenArray[2],
    contextType: childrenArray[3],
  };
  return (
    <components.SingleValue {...rest}>
      <ContextViewer contextMetadata={contextMetadata} />
    </components.SingleValue>
  );
};

const SIEInput = (props) => <components.Input tabIndex={-1} {...props} />;

const SIEOption = (props) => {
  const { children, ...rest } = props;
  const childrenArray = children.split("|");
  const contextMetadata = {
    imgUrl: childrenArray[0],
    displayName: childrenArray[1],
    contextValue: childrenArray[2],
    contextType: childrenArray[3],
  };
  return (
    <components.Option {...rest}>
      <ContextViewer contextMetadata={contextMetadata} isOption />
    </components.Option>
  );
};

/*
 * Defect: X23-1137
 * Shamelessly pilfered from https://github.com/JedWatson/react-select/issues/1020
 * Supposedly this issue for IE 11 has been fixed but I believe it's because we are
 * relying on an older version of react-select.  If we were to update to the recent 2.4.4 version,
 * This monkey patch hack could be removed since it should be handled in react-select
 */
function onBlur(e) {
  const focusedElement = document.activeElement;
  if (focusedElement.className.includes("sie-context-switcher__menu")) {
    const e = null;
    throw e;
  }
}

export default class ContextSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showForm: false };
    this.listenToContextSwitcherEvent =
      this.listenToContextSwitcherEvent.bind(this);
  }
  listenToContextSwitcherEvent(event) {
    const { contextType } = this.props;
    if (
      event.data.message === config.KODAMA_APP_OPEN_CONTEXT_EDITOR_MESSAGE &&
      contextType === "NP_COMM_ID"
    ) {
      this.setState({ showForm: true });
    }
  }
  onEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ showForm: true });
  };
  onHideForm = (event) => {
    this.setState({ showForm: false });
  };
  onSaveComplete = (newMetadata) => {
    const data = {
      payload: newMetadata,
      message: config.KODAMA_APP_CONTEXT_SWITCHER_MESSAGE,
    };
    sendMessageToIFrameElement(data);
    this.setState({ showForm: false }, () => {
      if (this.props.sendNotification)
        this.props.sendNotification({
          message: `Metadata for ${newMetadata.id} saved successfully.`,
          type: "success",
          hideAfter: 5,
          _id: new Date().getTime(),
        });

      if (this.props.onMetadataChange) this.props.onMetadataChange();
    });
  };

  componentDidMount() {
    window.addEventListener("message", this.listenToContextSwitcherEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listenToContextSwitcherEvent);
  }

  render() {
    const customizedComponents = {
      DropdownIndicator: SIEDropdownIndicator,
      Option: SIEOption,
      SingleValue: SingleValueContainer,
      Input: SIEInput,
    };
    const props = this.props;
    const noOptionsMessage = () => "No additional options to choose from";

    const styles = { paddingLeft: "0px", paddingRight: "0px" };
    const { options, contextType } = props;
    const value = options.find((option) => option.value === props.value);

    return (
      <React.Fragment>
        <div className="row context-viewer-container">
          <div className="col-md-4" style={styles}>
            <Select
              classNamePrefix="sie-context-switcher"
              components={customizedComponents}
              noOptionsMessage={noOptionsMessage}
              hideSelectedOptions={true}
              isSearchable={false}
              options={options}
              isClearable={false}
              onChange={props.onChange}
              value={value}
              onBlur={onBlur}
            />
          </div>
          <div className="col-md-2">
            <span
              onClick={this.onEditClick}
              onKeyDown={this.onEditClick}
              id="context-edit"
            >
              {" "}
              <Glyphicon glyph="cog" /> Edit{" "}
            </span>
          </div>
          <div className="col-md-6" />
        </div>
        {this.state.showForm && contextType === "NP_COMM_ID" && (
          <NPCommMetadataModal
            npCommId={this.props.value}
            contextType={this.props.contextType}
            showSandboxControls={this.props.showSandboxControls}
            onClose={this.onHideForm}
            onSaveComplete={this.onSaveComplete}
          />
        )}
        {this.state.showForm && contextType === "NP_TITLE_ID" && (
          <NPTitleMetadataModal
            npTitleId={this.props.value}
            contextType={this.props.contextType}
            onClose={this.onHideForm}
            onSaveComplete={this.onSaveComplete}
          />
        )}
      </React.Fragment>
    );
  }
}

ContextSwitcher.propTypes = {
  value: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  showSandboxControls: PropTypes.bool.isRequired,
  sendNotification: PropTypes.func,
  hasError: PropTypes.string,
  onMetadataChange: PropTypes.func,
};
