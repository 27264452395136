import React, { Component } from "react";
import { Route } from "react-router-dom";
import { pageContext } from "./analytics";
import { fetchMenuItems, getUserFeature } from "./clientService";
import config from "./config";
import ContextSwitcher from "./ContextSwitcher";
import LoadingSign from "./LoadingSign";
import NavigationBar from "./NavigationBar";
import ProxyComponent from "./ProxyComponent";
import {
  constructContextSwitcherOptions,
  determineCorrectUrlBasedOnPathname,
  determineCorrectUrlBasedOnQueryParameters,
  parseQueryObj,
  parseQueryParameters,
} from "./utils";

const { CONTEXT_VALUE, APP_ID_KEY, MENU_ITEM_KEY } = config;

function determineSandboxInitialState(history) {
  const { search } = history.location;
  const queryObj = search ? parseQueryParameters(search.substr(1)) : {};
  return queryObj[config.SANDBOX_MODE] === "true";
}

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.proxyRef = null;
    this.state = {
      sandboxEnabled: determineSandboxInitialState(props.history)
    };
  }
  triggerProxyRefresh = () => {
    this.proxyRef.resetIframe();
  };
  onContextChange = (option) => {
    const { history, applicationGroup, onUpdateMenuItems, sendNotification } =
      this.props;
    const { pathname, search } = history.location;
    const queryObj = search ? parseQueryParameters(search.substr(1)) : {};
    const newContextValue = option.value;
    fetchMenuItems(newContextValue)
      .then((menuItems) => {
        if (menuItems.length > 0) {
          queryObj[CONTEXT_VALUE] = newContextValue;
          if (queryObj[APP_ID_KEY] && queryObj[MENU_ITEM_KEY]) {
            const url = determineCorrectUrlBasedOnQueryParameters(
              menuItems,
              queryObj,
              applicationGroup
            );

            /* since this is assumed to be from a hard refresh and url has been revised,
                  remove these tracking query parameters */
            delete queryObj[APP_ID_KEY];
            delete queryObj[MENU_ITEM_KEY];

            history.push(url + parseQueryObj(queryObj));
          } else {
            const url = determineCorrectUrlBasedOnPathname(
              menuItems,
              pathname,
              applicationGroup
            );
            history.push(url + parseQueryObj(queryObj));
            //this.triggerProxyRefresh();
          }
        } else {
          //redirect to application group since no menu items are available
          history.push(
            `/${applicationGroup}?${CONTEXT_VALUE}=${newContextValue}`
          );
        }
        /*
            this is done here to avoid race condition between history.push and
            componentDidUpdate which at times gets updated npcomm but old menuItems
         */
        this.checkMenuItems(menuItems, sendNotification);
        onUpdateMenuItems(menuItems);
      })
      .catch((err) => {
        //alert user that they cannot change context value due to error
        console.log(err);
        sendNotification({
          type: "error",
          message:
            "There was a problem with changing the context. Please try again or contact Developer support.",
        });
      });
  };

  onSandboxToggled = (checked) => {
    const { history } = this.props;
    const { pathname, search } = history.location;
    const queryObj = search ? parseQueryParameters(search.substr(1)) : {};
    this.setState({ sandboxEnabled: checked }, () => {
      queryObj[config.SANDBOX_MODE] = !!checked;
      queryObj[config.SANDBOX_MODE_OLD] = !!checked; // keep this for backward compatibility, remove once DAT has been migrated
      history.push(pathname + parseQueryObj(queryObj));
    });
  };

  renderApp = () => {
    const {
      history,
      menuItems,
      applicationGroup,
      contextMetadata,
      appGroupContextType,
    } = this.props;
    //menuItems=null is treated as exception case
    if (contextMetadata === null || menuItems === null) {
      return <div />;
    }

    let contextValue = this.getContextValue(history);

    const page = pageContext.get();
    if (appGroupContextType === "NP_COMM_ID") {
      page.npCommID = contextValue || "empty";
    } else if (appGroupContextType === "NP_TITLE_ID") {
      page.npTitleID = contextValue || "empty";
    }

    const showSandboxControls = config.showSandboxToggle(
      applicationGroup,
      history.location.pathname
    );

    const { isSandboxUser } = this.props;

    return (
      <React.Fragment>
        {contextMetadata.length > 0 && (
          <ContextSwitcher
            options={constructContextSwitcherOptions(
              contextMetadata,
              appGroupContextType
            )}
            value={contextValue}
            onChange={this.onContextChange}
            contextType={appGroupContextType}
            sendNotification={this.props.sendNotification}
            onMetadataChange={this.props.onMetadataChange}
            showSandboxControls={
              showSandboxControls &&
              isSandboxUser &&
              this.state.sandboxEnabled
            }
          />
        )}
        <NavigationBar
          menuItems={menuItems}
          history={history}
          triggerProxyRefresh={this.triggerProxyRefresh}
          applicationGroup={applicationGroup}
          showSandboxControls={showSandboxControls && isSandboxUser}
          isSandboxUser={isSandboxUser}
          sandboxEnabled={this.state.sandboxEnabled}
          onSandboxToggled={this.onSandboxToggled}
        />
        <div className="container-fluid" id="iframe-container">
          <Route
            path="/:applicationGroup/app/:appIdentifier/:path*"
            render={(props) => (
              <ProxyComponent
                ref={(comp) => {
                  this.proxyRef = comp;
                }}
                {...props}
                isSandboxUser={isSandboxUser}
                menuItems={menuItems}
                appGroupContextType={appGroupContextType}
              />
            )}
          />
        </div>
      </React.Fragment>
    );
  };
  getContextValue = () => {
    const { contextMetadata, history } = this.props;
    if (contextMetadata?.length > 0 && history?.location?.search) {
      const queryObj = parseQueryParameters(history.location.search.substr(1));
      const contextValue = queryObj[CONTEXT_VALUE]?.trim();

      if (contextValue) {
        return contextValue;
      }
    }
    return null;
  };
  checkMenuItems = (menuItems, sendNotification) => {
    if (menuItems === null) {
      sendNotification({
        type: "error",
        message:
          "There has been an unexpected error while attempting to load the app. Please try again or contact Developer support.",
      });
      return;
    }

    if (menuItems.length === 0) {
      sendNotification({
        type: "info",
        message: this.getNoVisibleScreenMessage(),
        hideAfter: 5,
      });
    }
  };
  getNoVisibleScreenMessage = () => {
    const { appGroupContextType } = this.props;
    let contextValue = this.getContextValue();
    let suffix = "",
      contextPart = "";
    if (appGroupContextType && contextValue) {
      contextPart = ` under '${contextValue}'`;
      suffix = "Please select a different value and try again.";
    }

    return `You do not have access to any features in this application${contextPart}. ${suffix}`;
  };
  componentDidUpdate(prevProps, prevState) {
    const { menuItems, sendNotification, contextError } = this.props;
    //context is only loaded once
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      if (contextError.length > 0) {
        sendNotification({ type: "error", message: contextError });
        return;
      }
      this.checkMenuItems(menuItems, sendNotification);
    }

    //UGLY hack for resetting sandbox when the user toggles between menu items.
    if (
        !config.showSandboxToggle(
            this.props.applicationGroup,
            this.props.history.location.pathname
        ) &&
        prevState.sandboxEnabled
    ) {
      this.onSandboxToggled(false)
    }
  }
  render() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated) return <LoadingSign />;

    return <div className="App">{this.renderApp()}</div>;
  }
}

Homepage.defaultProps = {
  isAuthenticated: false,
  menuItems: [],
  isSandboxUser: false
};

export default Homepage;
