import axios from "axios";
import config from "../config";

let apiBase = config.kodama.apiUrl;

function getSandboxes() {
  const url = `${apiBase}/sandbox/v1/sandboxes`;
  return axios.get(url).then((response) => response.data);
}

export { getSandboxes };
