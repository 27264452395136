let kodamaConfig ={
  "local":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"dev",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "q1-np":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"qa",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "e1-np":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"qa",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "p1-spint":{
    apiUrl:"/api",
    gsmEnv:"prod",
    analyticsEnv:"prod",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "mgmt":{
    apiUrl:"https://kodama.e1-np.tools.playstation.net/",
    gsmEnv:"local"
  }
};

let deployEnv = window.deployEnv || 'local';
deployEnv = deployEnv.trim();

const config = {
  kodama: kodamaConfig[deployEnv],
  KA_PATH_KEY: "kaPath",
  MENU_ITEM_KEY: "menu_item_id",
  APP_ID_KEY: "app_id",
  CONTEXT_VALUE: "context_value",
  SANDBOX_MODE: "s_mode",
  SANDBOX_MODE_OLD: "sandbox_mode",
  KODAMA_APP_PATH_MESSAGE: "KODAMA_APP_PATH_CHANGE",
  KODAMA_APP_BREADCRUMB_MESSAGE: "KODAMA_APP_BREADCRUMB_CHANGE",
  KODAMA_APP_CONTEXT_SWITCHER_MESSAGE: "KODAMA_APP_CONTEXT_SWITCHER_MESSAGE",
  KODAMA_APP_OPEN_CONTEXT_EDITOR_MESSAGE: "KODAMA_APP_OPEN_CONTEXT_EDITOR_MESSAGE"
}

//SANDBOX_MODE_OLD for backward compatibility
config.persistQueryParams = [config.CONTEXT_VALUE, config.SANDBOX_MODE, config.SANDBOX_MODE_OLD]
config.isProdEnv = (["p1-spint"].includes(deployEnv));
config.qualtricsEnvs = ['e1-np']

const sModeGroups = ['uds','uds-qa', 'uds-local', 'webapitracer', "accntls","accntls-local"]

config.showSandboxToggle = function(applicationGroup, pathname) {

  //if env or application group doesn't match return false
  if(!(['local','q1-np','e1-np','p1-spint'].includes(deployEnv) && sModeGroups.includes(applicationGroup))){
    return false 
  }

  //if enabled but not dat return true
  if(!applicationGroup.startsWith('accntls')){
    return true;
  }

  //for dat return based on path!
  return pathname.endsWith('accntls/') || pathname.endsWith('accntls') || pathname.endsWith('/app/dat/') || pathname.endsWith('/app/dat-local/') || pathname.endsWith('/app/account-editor-local/') || pathname.endsWith('/app/amt/')
}

Object.freeze(config.kodama);
Object.freeze(config);

export default config;
